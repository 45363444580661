import React from "react"
//import { Link } from "gatsby"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

const Kontakt = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA – ¡CONTÁCTENOS!"
      description="El equipo de IMMMUNO NATURA espera sus preguntas, comentarios y críticas constructivas."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">CONTACTO</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column is-6 _headline">
                <p className="subtitle is-4">
                  El equipo de Immmuno Natura espera sus preguntas, comentarios
                  o críticas constructivas.
                </p>
                <p className="content">
                  Póngase en contacto con nosotros. Le respondemos en menos de
                  24 horas (en días laborables).
                </p>
              </div>
              <div className="column">
                <div className="_info">
                  <form
                    className="form"
                    name="es-contacto"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    action="/es/contacto-gracias"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="_inner">
                      <span>Solicitud de información</span>

                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Nombre y apellidos"
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="email"
                            placeholder="Correo electrónico"
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <textarea
                            className="textarea"
                            name="message"
                            placeholder="Su mensaje"
                            required
                          />
                        </div>
                      </div>
                      <label className="checkbox">
                        <input type="checkbox" required />
                        Acepto la política de{" "}
                        <a href="#">protección de datos.</a>
                      </label>
                    </div>
                    <div className="_buttonShop">
                      <button type="submit" class="button _kontaktBtn">
                        ENVIAR
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Kontakt
